import React from 'react'

import Box from 'components/Box'

const TableModule = ({ theaderData, data, note, ...props }) => (
  <Box as="table" mx="auto" borderRadius="0.5em 0.5em 0 0" overflow="hidden" width={1} {...props}>
    <thead>
      <Box as="tr">
        {theaderData.map(({ title, key }) =>
          <Box
            as="th"
            bg="blue"
            py="0.875rem"
            px="0.5rem"
            color="white"
            fontSize="1.125em"
            textAlign="center"
            key={key}
          >{title}</Box>
        )}
      </Box>
    </thead>
    <tbody>
      {Boolean(data.length) ? data.map((d, i) => (
        <Box as="tr" key={i}>
          {theaderData.map(({ key, Comp = Box, render, title, ...props }, k) => (
            <Comp as="td" bg={i % 2 ? 'white' : 'lightPurple'} key={key + k} py="0.75rem" {...props}>
              {render ? render(d[key], d) : d[key]}
            </Comp>
          ))}
        </Box>
      )) : (
        <Box as="tr">
          <Box
            as="td"
            bg="white"
            py="0.75rem"
            fontSize="1.5em"
            textAlign="center"
            colSpan={theaderData.length}>{note}</Box>
        </Box>
      )}
    </tbody>
  </Box>
)

export default TableModule
