import React from 'react'

import Box from 'components/Box'
import Text from 'components/Text'
import Board from 'components/Board'

const TableModule = ({ keyData, data, note, ...props }) => (
  <Board pt="0.625em" {...props}>
    {Boolean(data.length) ? data.map((d, i) => (
      <Box
        bg={i % 2 ? 'lightPurple' : 'white'}
        mx="1.125em"
        py="1.375em"
        px="0.75em"
        key={i}
      >
        {keyData.map(({ key, render, Comp = Text, ...props }, k) => (
          <Comp fontSize="1.125em" key={key} {...props}>
            {render ? render(d[key], d) : d[key]}
          </Comp>
        ))}
      </Box>
    )) : <Text mt="2em" fontSize="1.25em" textAlign="center">{note}</Text>}
  </Board>
)

export default TableModule
