import React from 'react'
import { FiExternalLink } from "react-icons/fi";

import TableModule from 'components/TableModule'
import Box from 'components/Box'
import Flex from 'components/Flex'
import Link from 'components/Link'

const theaderData = [
  {
    title: '調解委員會',
    key: 'department',
    Comp: Flex,
    alignItems: "center",
    justifyContent: "center",
    py: "1em",
    render: (children, data) => (
      <Link href={data.homepage} title={children} color="text">
        <Box.Inline mr="0.875rem">{children}</Box.Inline>
        <Box.Inline color="purple" lineHeight="1">
          <FiExternalLink />
        </Box.Inline>
      </Link>
    )
  },
  {
    title: '地址',
    key: 'address',
    px: "2em",
    render: (children, data) => `${data.zip} ${children}`,
  },
  {
    title: '電話',
    key: 'phone',
  },
]

const DesktopOffices = ({ data, ...props }) => (
  <TableModule theaderData={theaderData} data={data} />
)

export default DesktopOffices
