import React from 'react'
import { FiExternalLink } from "react-icons/fi";

import Box from 'components/Box'
import Flex from 'components/Flex'
import Link from 'components/Link'
import TableMobileModule from 'components/TableMobileModule'

const keyData = [
  {
    Comp: Flex,
    key: 'department',
    justifyContent: 'space-between',
    render: (children, data) => (
      <>
        <Box flex={1} fontSize="1.25rem" fontWeight="bold">{children}</Box>
        <Box
          right="0.875em"
          top="1.25rem"
          color="purple"
          width="1.25rem"
          as={(p) => <Link color="purple" {...p} />}
          href={data.homepage}
        >
          <FiExternalLink size="1.5rem" />
        </Box>
      </>
    )
  },
  {
    key: 'address',
    render: (children, data) => `${data.zip} ${children}`
  },
  {
    key: 'phone',
  },
]

const MobileOffices = ({ data, ...props }) => (
  <TableMobileModule data={data} keyData={keyData} {...props} />
)

export default MobileOffices
