import React from 'react';

import Button from 'components/Button';
import Box from 'components/Box'
import ContentLayout from 'components/ContentLayout'

import Data from 'contexts/data'
import useResponsive from 'contexts/mediaQuery/useResponsive'

import Mobile from './Mobile'
import Desktop from './Desktop'

const OfficePage = () => {
  // console.log(props)
  const { isTablet } = useResponsive()
  return (
    <ContentLayout title="各區調解會資訊">
      <Data path="/org">
        {(data) => {
          if (!data) return null
          const useData = data.filter(d => Boolean(d.lev))
          if (isTablet) {
            return <Mobile data={useData} />
          }
          return <Desktop data={useData}  />
        }}
      </Data>
      <Box textAlign="center" mt="3rem">
        <Button
          fontSize="1.25em"
          px="2.625rem"
          py="0.875rem"
          onClick={() => window.history.back()}
        >回上一頁</Button>
      </Box>
    </ContentLayout>
  )
};

export default OfficePage;
