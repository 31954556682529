import React from 'react'
import Box from 'components/Box'
import { responsive } from 'components/ThemeProvider/theme';

const Board = ({ children, noRadius, ...props }) => {
  return (
    <Box
      bg="white"
      borderRadius={noRadius ? '0 0 0.5em 0.5em' : '0.5em'}
      px={responsive('1em', '3.125em')}
      pt={responsive('1.25em', '3.125em')}
      pb="5.25em"
      position="relative"
      boxShadow="1px 3px 4px #C8C8C8"
      {...props}
    >
      {children}
    </Box>
  )
}

export default Board
